@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #236477;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border: 2px solid #236477;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.activeAccordion {
  background-color: #A2ED42;
  border: 2px solid #236477;
  color: #173042;
}


/* Style the accordion content title */
.accordion__title {
  /* font-family: "Open Sans", sans-serif; */
  font-weight: 600;
  font-size: 24px;
  text-align: left;
  color: #fff;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  color: #fff;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  transition: max-height 0.6s ease;
  border: 2px solid #173042;
  overflow: hidden;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 2%;
}

/* Style the accordion content text */
.accordion__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.accordion__text div{
  padding-top: 2%;
}

.accordion__content a{
  padding: 3%;
  margin: 3%;
  cursor: pointer;
  background-color: #173042;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  transition: .4s ease;
}

.accordion__content a:hover{
  background-color: #A2ED42;
  color: #173042;
}

@media only screen and (max-width:600px){
  .accordion__section .accordion__content .accordion__text{
    padding-bottom: 2vh;
  }

  .accordion__section .accordion__content .accordion__text iframe{
    width: 80vw;
    height: 38vh;
  }

  .accordion__section .accordion__content .accordion__text a{
    padding: 2vh;
  }
}
